<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      color="white"
      elevation="1"
      height="80"
    >
      <base-img
        :src="require('@/assets/udalogo.svg')"
        class="mr-3 hidden-xs-only"
        contain
        max-width="120"
        width="100%"
      />

      <!-- <base-img
        :src="require('@/assets/zero-logo-light.svg')"
        contain
        max-width="128"
        width="100%"
      /> -->

      <v-spacer />

      <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
        >
          <v-tab
            v-for="item in activeMenu"
            :key="item.name"
            :to="{ path: item.path }"
            :exact="item.name === 'Home'"
            :ripple="false"
            active-class="text--primary"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ $t(item.name) }}
          </v-tab>
        </v-tabs>
      </div>

      <!-- <v-menu
        bottom
        left
        min-width="200"
        offset-y
        origin="top right"
        transition="scale-transition"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-avatar
            size="30"
            class="ml-2"
            v-bind="attrs"
            v-on="on"
          >
            <v-img :src="computedAvatar" />
          </v-avatar>
        </template>
        <v-list>
          <v-list-item
            v-for="(p, i) in profile"
            :key="i"
            selectable
            dense
            link
          >
            <v-divider
              v-if="p.divider"
              dense
              :key="`divider-${i}`"
              class="mb-2 mt-2"
            />
            <v-list-item-title
              v-else
              :to="p.path"
              v-text="$t(p.title)"
            />
          </v-list-item>
        </v-list>
      </v-menu> -->

      <v-menu
        v-if="$store.state.auth.authStatus === 'success'"
        bottom
        left
        min-width="200"
        offset-y
        origin="top right"
        transition="scale-transition"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-avatar
            size="30"
            class="ml-2"
            v-bind="attrs"
            v-on="on"
          >
            <v-img :src="computedAvatar" />
          </v-avatar>
        </template>

        <v-list
          :tile="false"
          flat
          nav
        >
          <template v-for="(p, i) in profile">
            <v-divider
              v-if="p.divider"
              :key="`divider-${i}`"
              class="mb-1 mt-1"
            />

            <app-bar-item
              v-else
              :key="`item-${i}`"
              :to="p.path"
            >
              <v-icon left>
                {{ p.icon }}
              </v-icon>
              <v-list-item-title v-text="$t(p.title)" />
            </app-bar-item>
          </template>
        </v-list>
      </v-menu>

      <base-locale-switcher />

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="menu"
    />
  </div>
</template>

<script>
  import { VHover, VListItem } from 'vuetify/lib'

  export default {
    name: 'HomeAppBar',

    components: {
      HomeDrawer: () => import('./Drawer'),

      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    data: () => ({
      drawer: null,
      menu: [
        { name: 'sections.home', secured: false, path: '/' },
        { name: 'sections.about', secured: false, path: 'about' },
        { name: 'sections.contact', secured: false, path: 'contact-us' },
        { name: 'sections.plans', secured: false, path: 'plans' },
        { name: 'sections.login', secured: true, path: 'login' },
      ],
    }),

    computed: {
      profile () {
        return [
          { title: this.$t('sections.active-forms'), path: '/account/active', icon: 'mdi-earth' },
          { title: this.$t('common.projects'), path: '/account/projects', icon: 'mdi-tag-multiple' },
          { title: this.$t('common.settings'), path: '/account/settings', icon: 'mdi-account-cog' },
          { divider: true },
          { title: this.$t('oth.logout'), path: '/logout', icon: 'mdi-power' },
        ]
      },

      computedAvatar () {
        const image = ''
        return image ? this.$store.state.auth.user.avatar : require('@/assets/accounticon.png')
      },

      activeMenu: function () {
        // return this.menu
        if (this.$store.state.auth.authStatus === 'success') {
          return this.menu.filter(function (m) {
            return !m.secured
          })
        } else {
          return this.menu
        }
      },
      // items () {
      //   return [
      //     { text: 'sections.home', path: '/' },
      //     { text: 'sections.about', path: 'about' },
      //   ]
      // },
    },

  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
